import { expect } from 'chai';

// <qr-code-version>;<kassen-seriennummer>;<processType>;<processData>;
// <transaktions-nummer>;<signatur-zaehler>;
// <start-zeit>;<log-time>;<sig-alg>;<log-time-format>;<signatur>;<public-key>

const suites = {
  Syntaxprüfung: {
    'Anzahl Felder': function (data: string[]) {
      expect(data).to.have.lengthOf(12, 'Exakt 12 Felder erwartet');
    },
    'QR-Code Version': function (data: string) {
      expect(data[0]).to.match(/^V0/, 'QR-Code Version V0 erwartet');
    },
    Prozesstyp: {
      Definition: function (data: string) {
        expect(data[2]).to.match(
          /^(Kassenbeleg|Bestellung|SonstigerVorgang)/,
          'Gültiger Prozesstyp erwartet: Kassenbeleg, Bestellung, SonstigerVorgang'
        );
      },
      Version: function (data: string) {
        expect(data[2]).to.match(/^\w+-V1/, 'Version V1 erwartet');
      },
    },
    Transaktionsnummer: function (data: string) {
      expect(data[4]).to.match(/^\d+/, 'Numerische Transaktionsnummer erwartet');
    },
    Signaturzähler: function (data: string) {
      expect(data[5]).to.match(/^\d+/, 'Numerischer Signaturzähler erwartet');
    },
    Startzeit: function (data: string) {
      // YYYY-MM-DDThh:mm:ss.fffZ
      expect(data[6]).to.match(
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/,
        'Datumsformat YYYY-MM-DDThh:mm:ss.fffZ erwartet'
      );
    },
    Endzeit: function (data: string) {
      // YYYY-MM-DDThh:mm:ss.fffZ
      expect(data[7]).to.match(
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/,
        'Datumsformat YYYY-MM-DDThh:mm:ss.fffZ erwartet'
      );
    },
    Signaturalgorithmus: function (data: string) {
      const allowedAlgorithms = [
        'ecdsa-plain-SHA224',
        'ecdsa-plain-SHA256',
        'ecdsa-plain-SHA384',
        'ecdsa-plain-SHA512',
        'ecdsa-plain-SHA3-224',
        'ecdsa-plain-SHA3-256',
        'ecdsa-plain-SHA3-384',
        'ecdsa-plain-SHA3-512',
        'ecsdsa-plain-SHA224',
        'ecsdsa-plain-SHA256',
        'ecsdsa-plain-SHA384',
        'ecsdsa-plain-SHA512',
        'ecsdsa-plain-SHA3-224',
        'ecsdsa-plain-SHA3-256',
        'ecsdsa-plain-SHA3-384',
        'ecsdsa-plain-SHA3-512',
      ];

      expect(data[8]).to.match(/^([\w\d-]+)/, 'Angabe des Algorithmus erwartet');

      const usedAlgorithm = /^([\w\d-]+)/.exec(data[8]);
      expect(usedAlgorithm).to.not.be.a('null');
      expect(usedAlgorithm![1]).to.be.oneOf(allowedAlgorithms, 'Erlaubter Algorithmus erwartet');
    },
    'Log Zeitformat': function (data: string) {
      // https://kassensichv.com/downloads/DSFinV-K-Vers-2-2.pdf?v2
      const allowedFormats = [
        'unixTime',
        'utcTime',
        'utcTimeWithSeconds',
        'generalizedTime',
        'generalizedTimeWithMilliseconds',
      ];

      expect(data[9]).to.match(/^(\w+)/, 'Angabe des Zeitformats erwartet');

      const usedFormat = /^(\w+)/.exec(data[9]);
      expect(usedFormat).to.not.be.a('null');
      expect(usedFormat![1]).to.be.oneOf(allowedFormats, 'Erlaubtes Zeitformat erwartet');
    },
    Signatur: function (data: string) {
      expect(data[10]).to.match(/^([^;]+)/, 'Angabe der Signatur erwartet');

      const signature = /^([^;]+)/.exec(data[10]);
      expect(signature).to.not.be.a('null');
      expect(() => atob(signature![1])).to.not.throw(undefined, 'Ungültige Base64 Zeichenfolge');
    },
    'Öffentlicher Schlüssel': function (data: string) {
      expect(data[11]).to.match(/^([^;]+)$/, 'Angabe des öffentlichen Schlüssels erwartet');

      const publicKey = /^([^;]+)$/.exec(data[11]);
      expect(publicKey).to.not.be.a('null');
      expect(() => atob(publicKey![1])).to.not.throw(undefined, 'Ungültige Base64 Zeichenfolge');
    },
  },
};

export default suites;
