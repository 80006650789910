const suites = {
  Signatur: {
    Validität: async function (data: string[]) {
      try {
        const response = await fetch(process.env.REACT_APP_SERVER_URL + 'verify', {
          method: 'post',
          body: JSON.stringify(data),
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          credentials: 'include',
        });
        const result = (await response.json()) as { msg: string | boolean };
        if (result.msg === true || result.msg === 'success' || result.msg === 'Verified OK') {
          return true;
        }

        return new Error(result?.msg || 'unbekannt');
      } catch (e: unknown) {
        return new Error('Serverseitige Signaturprüfung fehlgeschlagen oder aktuell nicht möglich.');
      }
    },
  },
};

export default suites;
