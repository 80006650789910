// <Vorgangstyp>^<Brutto-Steuerumsätze>^<Zahlungen>

const currency = (number: number, currency: string = 'EUR') =>
  new Intl.NumberFormat('de-DE', { style: 'currency', currency }).format(number);

const suites = {
  Prozesstyp: {
    Vorgangstyp: function (data: string[]) {
      const value = /^(Kassenbeleg|Bestellung|SonstigerVorgang)/.exec(data[2]);

      return {
        message: value !== null && value.length > 0 ? value[1] : 'unbekannt',
      };
    },
    Kassenbeleg: {
      Transaktionstyp: function (data: string[]) {
        const isReceipt = /^(Kassenbeleg)-V\d/.test(data[2]);
        if (!isReceipt) {
          return {
            ignore: true,
          };
        }

        const value =
          /^(Beleg|AVTransfer|AVBestellung|AVTraining|AVBelegstorno|AVBelegabbruch|AVSachbezug|AVSonstige|AVRechnung)\^/.exec(
            data[3]
          );
        return {
          message: value !== null && value.length > 0 ? value[1] : 'unbekannt',
        };
      },
      'Brutto-Steuerumsätze': function (data: string[]) {
        const isReceipt = /^(Kassenbeleg)-V\d/.test(data[2]);
        if (!isReceipt) {
          return {
            ignore: true,
          };
        }

        const value = /^[^^]+\^((?:\d+\.\d{2}_){4}\d+\.\d{2})/.exec(data[3]);

        if (null === value || value.length < 2) {
          return {
            message: 'unbekannt',
          };
        }

        const values = value[1].split('_').map((value) => parseFloat(value));

        return {
          message: (
            <ul>
              <li>
                Allgemeiner Steuersatz (aktueller und historischer):
                <br />
                <strong>{currency(values[0])}</strong>
              </li>
              <li>
                Ermäßigter Steuersatz (aktueller und historischer):
                <br />
                <strong>{currency(values[1])}</strong>
              </li>
              <li>
                Durchschnittsatz (
                <a
                  rel="external help noreferrer"
                  target="_blank"
                  href="https://www.gesetze-im-internet.de/ustg_1980/__24.html"
                >
                  §24(1)Nr.3 UStG
                </a>
                ) (aktueller und historischer):
                <br />
                <strong>{currency(values[2])}</strong>
              </li>
              <li>
                Durchschnittsatz (
                <a
                  rel="external help noreferrer"
                  target="_blank"
                  href="https://www.gesetze-im-internet.de/ustg_1980/__24.html"
                >
                  §24(1)Nr.1 UStG
                </a>
                ) (aktueller und historischer):
                <br />
                <strong>{currency(values[3])}</strong>
              </li>
              <li>
                0%:
                <br />
                <strong>{currency(values[4])}</strong>
              </li>
            </ul>
          ),
        };
      },
      Zahlungen: function (data: string[]) {
        const isReceipt = /^(Kassenbeleg)-V\d/.test(data[2]);
        if (!isReceipt) {
          return {
            ignore: true,
          };
        }

        const value = /^(?:[^^]+\^){2}(\d+\.\d{2}:(?:Bar|Unbar)(:[A-Z]{3})?_?)*/.exec(data[3]);

        if (null === value || value.length < 2) {
          return {
            message: 'unbekannt',
          };
        }

        const payments = value[1].split('_').map((payment) => {
          const paymentData = payment.match(/^(\d+\.\d{2}):(Bar|Unbar)(?::([A-Z]{3}))?$/)!;

          return {
            value: parseFloat(paymentData[1]),
            type: paymentData[2],
            currency: paymentData[3] || 'EUR',
          };
        });

        return {
          message: (
            <ul>
              {payments.map((payment, index) => {
                return (
                  <li key={index}>
                    {payment.type}: {currency(payment.value, payment.currency)}
                  </li>
                );
              })}
            </ul>
          ),
        };
      },
    },
    Bestellung: {
      Positionen: function (data: string[]) {
        const isOrder = /^(Bestellung)-V\d/.test(data[2]);
        if (!isOrder) {
          return {
            ignore: true,
          };
        }

        return {
          message: 'unbekannt',
        };
      },
    },
  },
};

export default suites;
