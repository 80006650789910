// <qr-code-version>;<kassen-seriennummer>;<processType>;<processData>;
// <transaktions-nummer>;<signatur-zaehler>;
// <start-zeit>;<log-time>;<sig-alg>;<log-time-format>;<signatur>;<public-key>

const suites = {
  Rohdaten: {
    'QR-Code Version': function (data: string[]) {
      const value = /^(V0)/.exec(data[0]);
      return {
        message: value !== null && value.length > 0 ? value[1] : 'unbekannt',
      };
    },
    'Kassen Seriennummer': function (data: string[]) {
      const value = data[1];
      return {
        message: value !== null ? value : 'unbekannt',
      };
    },
    Prozesstyp: function (data: string[]) {
      const value = /^([^;]+)-V\d/.exec(data[2]);
      return {
        message: value !== null && value.length > 0 ? value[1] : 'unbekannt',
      };
    },
    'Prozesstyp Version': function (data: string[]) {
      const value = /-V\d/.exec(data[2]);
      return {
        message: value !== null ? value.toString().substring(1) : 'unbekannt',
      };
    },
    Transaktionsnummer: function (data: string[]) {
      const value = data[4];
      return {
        message: value !== null ? value : 'unbekannt',
      };
    },
    Signaturzähler: function (data: string[]) {
      const value = data[5];

      return {
        message: value !== null ? value : 'unbekannt',
      };
    },
    Startzeit: function (data: string[]) {
      const value = data[6];

      return {
        message: value !== null ? value : 'unbekannt',
      };
    },
    Endzeit: function (data: string[]) {
      const value = data[7];

      return {
        message: value !== null ? value : 'unbekannt',
      };
    },
    'Signatur Algorithmus': function (data: string[]) {
      const value = data[8];

      return {
        message: value !== null ? value : 'unbekannt',
      };
    },
    'Log Zeitformat': function (data: string[]) {
      const value = data[9];

      return {
        message: value !== null ? value : 'unbekannt',
      };
    },
    Signatur: function (data: string[]) {
      const value = data[10];

      return {
        message: value !== null ? value : 'unbekannt',
      };
    },
    'Public Key': function (data: string[]) {
      const value = data[11];

      return {
        message: value !== null ? value : 'unbekannt',
      };
    },
  },
};

export default suites;
