import { Flex, Icon, IconProps, Link, Text, useColorModeValue } from '@chakra-ui/react';
import { IconType } from 'react-icons/lib';
import { FaQuestion } from 'react-icons/fa';

export default function NavItem({ link, text, icon = FaQuestion, isFocus = false }: navItemProps) {
  const bg = useColorModeValue('gray.100', 'brand.500');
  const isFocusProps: IconProps = isFocus
    ? {
        top: '-50%',
        pos: 'absolute',
        p: '0.5em',
        rounded: 'full',
        border: bg + ' 3px solid',
        bg,
        boxSizing: 'content-box',
        fontSize: '2.5em',
      }
    : {
        pos: 'relative',
        p: 0,
        fontSize: '2em',
      };

  return (
    <Link _hover={{ textDecoration: 'none', color: 'unset' }} onClick={link} py="0.75em" flexGrow={1}>
      <Flex flexDir={'column'} align={'center'} h="100%" justify="end">
        <Icon as={icon} {...isFocusProps} />
        <Text> {text} </Text>
      </Flex>
    </Link>
  );
}

export type navItemProps = { link: () => void; icon?: IconType; text: string; isFocus?: boolean };
