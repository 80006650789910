import { AssertionError } from 'chai';

type NestedValidationSuite = ValidationSuite | ((data: string[]) => Information | undefined);

interface ValidationSuite {
  [suiteName: string]: NestedValidationSuite;
}

interface ValidationSuites {
  [suiteName: string]: ValidationSuite;
}

export interface Information {
  message: string;
}

export interface IgnoreResult {
  ignore: true;
}

export type NestedSuiteResult = true | IgnoreResult | Information | Error | SuiteResults;

export interface SuiteResults {
  [suiteName: string]: NestedSuiteResult;
}

// https://dfka.net/wp-content/uploads/2019/08/20190802_DSFinV_K_V_2_0.pdf
class TSEValidator {
  private validations: ValidationSuites[] = [
    require('./validators/00-basic-syntax').default,
    require('./validators/10-processType.tsx').default,
    require('./validators/20-signature').default,
    require('./validators/99-raw-information').default,
  ];

  public static isInformation(obj: any): obj is Information {
    return typeof obj === 'object' && 'message' in obj;
  }

  public static isIgnored(obj: any): obj is IgnoreResult {
    return typeof obj === 'object' && 'ignore' in obj && obj['ignore'];
  }

  public static isValidSuite(suite: SuiteResults): boolean {
    return Object.entries(suite).every(([name, result]) => {
      if (result) {
        if (result instanceof Error) {
          return false;
        }

        if (result === true || TSEValidator.isInformation(result) || TSEValidator.isIgnored(result)) {
          return true;
        }
        return this.isValidSuite(result);
      } else {
        return false;
      }
    });
  }

  public async validate(data: string): Promise<SuiteResults> {
    const results: SuiteResults = {};
    for (const suites of this.validations) {
      for (const suiteName of Object.keys(suites)) {
        results[suiteName] = await this.runSuite([suiteName], suites[suiteName], data);
      }
    }

    return results;
  }

  private async runSuite(suitePath: string[], suite: NestedValidationSuite, data: string): Promise<NestedSuiteResult> {
    const functionOrNestedSuite = suite;
    if (typeof functionOrNestedSuite === 'function') {
      let result: Information | undefined;
      try {
        result = functionOrNestedSuite(data.split(';'));
      } catch (e) {
        if (e instanceof AssertionError) {
          return e;
        }

        throw e;
      }

      return result || true;
    }

    const results: SuiteResults = {};
    for (const suiteName of Object.keys(suite)) {
      results[suiteName] = await this.runSuite([suiteName], functionOrNestedSuite[suiteName], data);
    }

    return results;
  }
}

export { TSEValidator };
