import { Box, Image, Flex, Link, useColorMode, useColorModeValue } from '@chakra-ui/react';
import NavItem, { navItemProps } from './NavItem';

export default function Navbar({ navigation }: { navigation: navItemProps[] }) {
  const { colorMode, toggleColorMode } = useColorMode();
  const bgColor = useColorModeValue('brand.400', 'gray.700');
  return (
    <>
      <Box pos="sticky" top="0" bg={bgColor} w="100%" zIndex="20">
        <Flex align={'center'}>
          <Link variant="button" py="0.35em" px="0.65em" ml={'0.4rem'} mr={'auto'}>
            tse.works
          </Link>
          <Flex display={{ base: 'none', md: 'flex' }}>
            {navigation.map((navItem) => {
              return (
                <Link variant="button" py="0.5em" px="0.85em" onClick={navItem.link}>
                  {navItem.text}
                </Link>
              );
            })}
          </Flex>
          <Link
            py="0.5em"
            px="0.85em"
            onClick={() => {
              toggleColorMode();
            }}
          >
            <Image
              src="https://meeva.de/wp-content/uploads/2020/01/meeva_favicon.ico"
              filter={colorMode === 'light' ? 'grayscale(100%)' : ''}
            />
          </Link>
        </Flex>
      </Box>
      <Box as="nav" pos="fixed" bottom="0" bg={bgColor} w="100%" zIndex="20" display={{ sm: 'block', md: 'none' }}>
        <Flex justify="space-around" alignItems={'stretch'} fontSize={'0.75rem'}>
          {navigation.map((navItem) => {
            return <NavItem {...navItem} />;
          })}
        </Flex>
      </Box>
    </>
  );
}
