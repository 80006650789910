import React, { useEffect, useState } from 'react';
import { MdCheck, MdHighlightOff } from 'react-icons/md';
import { SuiteResults, TSEValidator } from './TSEValidator';
import 'mocha/mocha.css';
import { ValidationSuiteResult } from './ValidationSuiteResult';
import { Box, Flex, Icon } from '@chakra-ui/react';

function ValidationResult({ data }: { data: string }) {
  const [validationResults, setValidationResults] = useState<SuiteResults | null>(null);
  const [validationResult, setValidationResult] = useState(false);

  useEffect(() => {
    const runValidation = async () => {
      const validator = new TSEValidator();
      const results = await validator.validate(data);

      setValidationResult(await TSEValidator.isValidSuite(results));
      setValidationResults(results);
    };

    runValidation();
  }, [setValidationResult, data]);
  return (
    <Flex direction={'column'} alignItems={'center'} my={'2'}>
      {validationResults && (
        <>
          <Icon
            as={validationResult ? MdCheck : MdHighlightOff}
            fontSize={'3em'}
            color={validationResult ? 'gray.400' : 'brand.500'}
          />
          <Box p={'2'} w={'full'}>
            {Object.entries(validationResults).map(([suiteName, result], index) => (
              <ValidationSuiteResult key={index} name={suiteName} result={result} />
            ))}
          </Box>
        </>
      )}
    </Flex>
  );
}

export default ValidationResult;
