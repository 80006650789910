import { useState } from 'react';
import { Box, FormControl, Button, FormLabel, Textarea } from '@chakra-ui/react';

function TextInput({ onData }: { onData: (data: string) => void }) {
  const [text, setText] = useState('');

  return (
    <Box py={'1em'}>
      <Box my="3">
        <FormControl>
          <FormLabel>Rohdaten eingeben:</FormLabel>
          <Textarea onChange={(e) => setText(e.target.value)} rows={8} resize="vertical" size="lg" />
          <Button disabled={!text} onClick={() => onData(text)} mt={'4'}>
            Auswerten
          </Button>
        </FormControl>
      </Box>
    </Box>
  );
}

export { TextInput };
