import { useCallback, useMemo, useState } from 'react';
import ValidationResult from './ValidationResult';
import QR from './views/QR';
import Text from './views/Text';
import Info from './views/Info';
import { Box, Container, useColorMode } from '@chakra-ui/react';
import Navbar from './components/Navbar';
import { MdFilePresent, MdInfoOutline, MdQrCode } from 'react-icons/md';
import { navItemProps } from './components/Navbar/NavItem';
type InputMode = 'qr' | 'info' | 'text';

function App() {
  const [data, setData] = useState<string | null>(null);
  const [inputMode, setInputMode] = useState<InputMode>('qr');
  const { colorMode } = useColorMode();

  const switchInputMode = useCallback(
    (mode: InputMode) => {
      setData(null);
      setInputMode(mode);
    },
    [setData, setInputMode]
  );
  const navigation: navItemProps[] = [
    {
      link: () => {
        switchInputMode('text');
      },
      text: 'Text',
      icon: MdFilePresent,
    },
    {
      link: () => {
        switchInputMode('qr');
      },
      text: 'Scan',
      icon: MdQrCode,
      isFocus: true,
    },
    {
      link: () => {
        switchInputMode('info');
      },
      text: 'Info',
      icon: MdInfoOutline,
    },
  ];

  const containerContent = useMemo(() => {
    if (data) {
      return <ValidationResult data={data} />;
    }

    if (inputMode === 'qr') {
      return <QR onData={setData} />;
    }

    if (inputMode === 'text') {
      return <Text onData={setData} />;
    }

    return <Info />;
  }, [data, inputMode]);

  return (
    <Box>
      <Navbar navigation={navigation} />
      <Container variant={colorMode} colorScheme={colorMode} maxW={'container.lg'} pb={'4em'} mb={'4em'}>
        {containerContent}
      </Container>
    </Box>
  );
}

export default App;
