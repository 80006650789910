import { NestedSuiteResult, TSEValidator } from './TSEValidator';
import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Code,
  Collapse,
  Flex,
  Icon,
  List,
  ListIcon,
  ListItem,
  StackDivider,
  useColorModeValue,
  VStack,
  Text,
} from '@chakra-ui/react';
import { MdAdd, MdCheck, MdHighlightOff, MdInfo, MdRemove } from 'react-icons/md';

export function ValidationSuiteResult({
  name,
  result,
  nested = false,
}: {
  name: string;
  result: NestedSuiteResult;
  nested?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [isSuiteValid, setIsSuiteValid] = useState(false);
  const backgroundColor = useColorModeValue('gray.100', 'gray.900');

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  useEffect(() => {
    // auto-collapse successful suites
    setOpen(!isSuiteValid);
  }, [isSuiteValid]);

  useEffect(() => {
    if (result) {
      setIsSuiteValid(TSEValidator.isValidSuite({ name: result }));
    }
  }, [result]);

  if (TSEValidator.isIgnored(result)) {
    return null;
  }

  if (result === true) {
    return (
      <List px={2}>
        <ListItem py={1}>
          <ListIcon as={MdCheck} />
          {name}
        </ListItem>
      </List>
    );
  }

  if (result instanceof Error) {
    const [message, assertionException] = result.message.split(': expected ', 2);

    return (
      <List outline={'solid 0.15rem'} outlineColor={'brand.500'} rounded={'sm'}>
        <ListItem onClick={() => setOpen(!open)}>
          <Flex alignItems={'center'} bg={backgroundColor} p={'0.5rem'}>
            <ListIcon as={MdHighlightOff} color={'brand.500'} />
            {name}
            <Icon ml={'auto'} as={open ? MdRemove : MdAdd} />
          </Flex>
        </ListItem>
        <Collapse in={open}>
          <ListItem bg={'rgba(0,0,0,0.'}>
            <Text p={2}>{message}</Text>
            {assertionException && (
              <Box as="pre" mt={2} p={2} bg={'white'}>
                <Code color={'black'}>Expected {assertionException}</Code>
              </Box>
            )}
          </ListItem>
        </Collapse>
      </List>
    );
  }

  if (TSEValidator.isInformation(result)) {
    return (
      <List py={0.5} px={'0.5rem'} rounded={'md'}>
        <ListItem>
          <ListIcon as={MdInfo} />
          {name}
        </ListItem>
        <ListItem py={0.5} px={1.5}>
          {result.message}
        </ListItem>
      </List>
    );
  }

  return (
    <List
      rounded={'md'}
      outline={'solid 0.15rem'}
      outlineColor={isSuiteValid ? 'gray.400' : 'brand.500'}
      mb={nested ? 0 : 2}
      overflow={'none'}
    >
      <ListItem onClick={toggleOpen} bg={backgroundColor}>
        <Flex alignItems={'center'} p={'0.5rem'}>
          {isSuiteValid && <ListIcon as={MdCheck} />}
          {!isSuiteValid && <ListIcon as={MdHighlightOff} color={'brand.500'} />}
          {name}
          <Icon ml={'auto'} as={open ? MdRemove : MdAdd} />
        </Flex>
      </ListItem>
      <Collapse in={open}>
        <VStack
          align="stretch"
          divider={<StackDivider borderColor={'gray.400'} />}
          spacing={2}
          p={2}
          borderTopWidth={'0.15em'}
          borderColor={isSuiteValid ? 'gray.400' : 'brand.500'}
          borderStyle={'solid'}
        >
          {Object.entries(result).map(([suiteName, result], index) => (
            <ValidationSuiteResult key={index} nested={true} name={suiteName} result={result || {}} />
          ))}
        </VStack>
      </Collapse>
    </List>
  );
}
