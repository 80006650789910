import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react';

import QrScanner from 'qr-scanner';
import theme from './theme';
// eslint-disable-next-line
const QrScannerWorkerPath = require('!!file-loader!qr-scanner/qr-scanner-worker.min.js').default;
QrScanner.WORKER_PATH = QrScannerWorkerPath;

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
