import { Box, Flex } from '@chakra-ui/react';
import QrScanner from 'qr-scanner';
import { useCallback, useRef } from 'react';

function ViewFinder() {
  return (
    <svg viewBox="0 0" width="100%" height={'100%'}>
      <defs>
        <mask id="mask" x="0" y="0" width="100" height="100">
          <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
          <rect
            alignmentBaseline="middle"
            x="50%"
            y="50%"
            rx="5"
            ry="5"
            transform="translate(-110,-110)"
            width="220"
            height="220"
          />
        </mask>
      </defs>
      <rect x="0" y="0" width="100%" height="100%" mask="url(#mask)" fillOpacity="0.4" />
    </svg>
  );
}

function QrReader({ onResult }: { onResult: (text: string) => void }) {
  let qrScanner = useRef<QrScanner | undefined>();

  const videoRef = useCallback(
    async (node) => {
      if (node !== null) {
        if (await QrScanner.hasCamera()) {
          qrScanner.current = new QrScanner(node, (result) => onResult(result));
          qrScanner.current.setInversionMode('both');
          try {
            qrScanner.current.start();
          } catch (e) {
            console.error(e);
          }
        }
      } else if (qrScanner.current) {
        qrScanner.current.destroy();
        qrScanner.current = undefined;
      }
    },
    [onResult, qrScanner]
  );

  return (
    <Box>
      <Flex overflow={'hidden'} pos="relative">
        <video ref={videoRef} style={{ width: '100%', height: 'auto', maxHeight: '80%' }} />
        <Flex pos="absolute" top="0" left="0" right="0" bottom="0" zIndex="5" align="center" justify="center">
          <ViewFinder />
        </Flex>
      </Flex>
    </Box>
  );
}

function Camera({ onData }: { onData: (data: string) => void }) {
  return (
    <QrReader
      onResult={(result) => {
        onData(result);
      }}
    />
  );
}

export { Camera };
