import { Camera } from '../components/Input/Camera';
import { ImageInput } from '../components/Input/ImageInput';

export default function QR({ onData }: { onData: (data: string) => void }) {
  return (
    <div>
      <Camera onData={onData} />
      <ImageInput onData={onData} />
    </div>
  );
}
