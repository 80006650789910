import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';
import { Box, Button, FormControl, FormHelperText, FormLabel, Input, Text } from '@chakra-ui/react';

function ImageInput({ onData }: { onData: (data: string) => void }) {
  const [image, setImage] = useState<string | ArrayBuffer | null>(null);
  const [data, setData] = useState<string | null>(null);
  const [hasError, setHasError] = useState(false);
  const uploadRef = useRef<HTMLInputElement>(null);

  const readSelectedFile = useCallback(
    (e: SyntheticEvent) => {
      const fileInput = e.target as HTMLInputElement;
      console.log(fileInput.files);

      if (!fileInput.files || !fileInput.files[0]) {
        setData(null);
        setImage(null);
        //error
        return;
      }
      var file = fileInput.files[0];
      var reader = new FileReader();
      reader.addEventListener('load', (event) => {
        const result = event.target?.result || null;
        setImage(result);
      });
      reader.readAsDataURL(file);
    },
    [setImage]
  );

  useEffect(() => {
    if (!image) {
      return;
    }

    QrScanner.scanImage(image as any as string)
      .then((result) => {
        setData(result);
        setHasError(false);
      })
      .catch((err) => {
        setHasError(true);
        setData(null);
      });
  }, [image]);

  console.log(uploadRef.current?.files);

  return (
    <Box pb={2}>
      <FormControl py={'2'} isInvalid={hasError}>
        <FormLabel>
          <Text textAlign={{ base: 'center', sm: 'left' }}>oder als</Text>
          <Button my={1} onClick={() => uploadRef.current?.click()}>
            Bild hochladen
          </Button>
          <FormLabel>{uploadRef.current?.files ? uploadRef.current.files[0].name : 'Keine Datei ausgewählt'}</FormLabel>
          <Input ref={uploadRef} type="file" onChange={readSelectedFile} display="none" />
        </FormLabel>
        <FormHelperText>
          Für ein optimales Ergebnis das Bild auf den QR-Code zuschneiden und einen gleichmäßigen Rand lassen.
        </FormHelperText>
        <Button my={2} disabled={hasError || !data} onClick={() => data && onData(data)}>
          Auswerten
        </Button>
      </FormControl>
    </Box>
  );
}

export { ImageInput };
