import { extendTheme, ThemeConfig } from '@chakra-ui/react';

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: true,
};

const colors = {
  brand: {
    0: '#faebeb',
    100: '#efc2c4',
    200: '#e59a9d',
    300: '#db7176',
    400: '#d0484e',
    500: '#b72f35',
    600: '#8e2429',
    700: '#651a1d',
    800: '#3d1012',
    900: '#140506',
  },
};

const Link = {
  variants: {
    button: {
      _hover: {
        color: 'unset',
        textDecoration: 'none',
        background: 'rgba(0,0,0,0.2)',
      },
      rounded: 'sm',
    },
  },
};

const theme = extendTheme({ config, colors, components: { Link } });

export default theme;
